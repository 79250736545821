import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoteComponent = _resolveComponent("NoteComponent")!

  return (_openBlock(), _createBlock(_component_NoteComponent, {
    encounterId: _ctx.props.claim.encounterId,
    patientId: _ctx.props.claim.encounter.patient.id,
    claimId: _ctx.props.claim.id
  }, null, 8, ["encounterId", "patientId", "claimId"]))
}