
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import NoteComponent from "./../note/NotesComponent.vue";

export interface Data {
  claim: any;
  createNewMode: boolean;
  updateOnly: boolean;
  isReady: boolean;
}
export default defineComponent({
  name: "ClaimNoteComponent",
  props: ["claim"],
  components: { NoteComponent },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");

    onMounted(async () => {
      organizationId.value = getOrganization();
    });

    return {
      props,
      organizationId,
    };
  },
});
